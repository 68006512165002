<template>
  <v-card>
    <v-card-title class="text-h6">{{ $t("labels.detail") }}</v-card-title>

    <v-card-text>
      <v-simple-table class="table-padding-2-no-top">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">{{ $t("labels.warehouse_1") }}</th>
              <th class="text-center">{{ $t("labels.pos") }}</th>
              <th class="text-center">{{ $t("labels.barcode") }}</th>
              <th class="text-center">{{ $t("labels.sku") }}</th>
              <th class="text-center">{{ $t("labels.category") }}</th>
              <th class="text-center">{{ $t("labels.name") }}</th>
              <th class="text-center">{{ $t("labels.goods_description") }}</th>
              <th class="text-center">{{ $t("labels.size") }}</th>
              <th class="text-center">
                {{ $t("labels.available_quantity_1") }}
              </th>
              <th class="text-center">
                {{ $t("labels.uid_available") }}
              </th>
              <th class="text-center">
                {{ $t("labels.goods_cost_by_uid") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center">
              <td>{{ item.warehouse_code }}</td>
              <td>{{ item.pos_code }}</td>
              <td>{{ item.customer_goods_barcode }}</td>
              <td>{{ item.sku }}</td>
              <td>{{ item.category_name }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.size }}</td>
              <td>{{ formatNumber(item.available) }}</td>
              <td>{{ formatNumber(totalUidAvailable) }}</td>
              <td>{{ totalCost ? formatNumber(totalCost) : "" }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-simple-table
        class="table-padding-2-no-top mt-5"
        fixed-header
        height="500px"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">{{ $t("labels.supplier") }}</th>
              <th class="text-center">{{ $t("labels.session_code") }}</th>
              <th class="text-center">
                {{ $t("labels.available_quantity_1") }}
              </th>
              <th class="text-center">{{ $t("labels.total_cost_1") }}</th>
              <th class="text-center">{{ $t("labels.value") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" v-for="ses in items" :key="ses.tracking_id">
              <td>{{ ses.supplier_name }}</td>
              <td>{{ ses.tracking_id }}</td>
              <td>{{ formatNumber(ses.uid_available) }}</td>
              <td>{{ ses.cost !== null ? formatNumber(ses.cost) : "" }}</td>
              <td>
                {{ ses.cost !== null ? formatNumber(ses.total_cost) : "" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ReportDetail",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    totalUidAvailable: 0,
    totalCost: 0,
  }),
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      httpClient
        .post("/goods-report-detail", {
          id_goods: this.item.id,
          id_warehouse: this.item.id_warehouse,
        })
        .then(({ data }) => {
          this.items = [...data].map((item) => {
            const total_cost = +item.uid_available * item.cost;
            this.totalUidAvailable += +item.uid_available;
            this.totalCost += total_cost;
            return {
              ...item,
              total_cost,
            };
          });
        });
    },
  },
};
</script>
